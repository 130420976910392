@import "_colors.css";
@import "_custom-media.css";
@import "_typography.css";

:root {
  /*  GENERAL         */
  --nice-border-radius: 3px;

  /*  NAV
  Space to top of window  */
  --nav-top-padding-mobile: 16px;
  --nav-top-padding-tablet: 32px;
  --nav-top-padding-desktop: 72px;

  /*  Width of the sidebar (on Desktop) */
  --nav-sidebar-width: 280px;

  /*  GRID


  Gutter between items in the grid views */
  --grid-gutter: 25px;

  /*  GALLERIES


  Max gallery width, for click galleries */
  --gallery-scroll-max-width: 800px;
  --gallery-click-max-width: 112vh;

  /*  Gutter between images */
  --gallery-image-gutter: 4px;

  /*  Fade transition duration for gallery images */
  --gallery-fade-transition-duration: 1.8s;

  /*  TOGGLE CONTAINERS */
  --toggle-container-border: 1px solid var(--border-subtle-gray);
}
