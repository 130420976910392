:root {
  --font-family-sans: "Avenir";
  /*
  --font-family-sans was previously:
  @mixin sans() {
    font-family: 'Avenir';
  }
  */

  --font-family-serif: "Cormorant Garamond";
  /*
  --font-family-serif was previously:
  @mixin serif() {
    font-family: 'Cormorant Garamond';
  }
  */

  --letter-spacing-smallcaps: 0.2em;
  /*
  --letter-spacing-smallcaps was previously:
  @mixin spaced-caps() {
    letter-spacing: 0.2em;
    text-transform: uppercase;
  }
  */

  --text-rich-black: #000000;
  --text-black: #272727;
  --text-dark-gray: #474747;
  --text-medium-gray: #777777;
  --text-light-gray: #949494;
  --text-subtle-gray: #a7a7a7;
  --text-ghost-gray: #d7d7d7;
  --text-medium-burgundy: #845353;

  --font-size-base-px: 16;
  --font-size-base: calc(calc(16 / var(--font-size-base-px)) * 1rem);
  --font-size-medium: calc(calc(14.5 / var(--font-size-base-px)) * 1rem);
  --font-size-small: calc(calc(13.5 / var(--font-size-base-px)) * 1rem);
  --font-size-tiny: calc(calc(11 / var(--font-size-base-px)) * 1rem);
  --font-size-miniscule: calc(calc(9 / var(--font-size-base-px)) * 1rem);

  --line-height-base: 1.4;
  --line-height-title: 1.4;
  --line-height-testimonial: 1.6;
  --line-height-body: 1.9;
}
