@import "settings/__index.css";
@import "_reset.css";

html,
body {
  color: var(--text-black);
  font-size: calc(var(--font-size-base-px) * 1px);
  height: 100%;
  line-height: var(--line-height-base);
  position: relative;
  width: 100%;
}

.clear {
  clear: both;
}

[data-action]:hover {
  cursor: pointer;
}

/* Lazy loaded images */
.lazyImage-parent {
  transition: transform 0.6s;

  & .lazyImage-img {
    opacity: 0;
    transition: opacity 0.6s;
  }

  &.loaded .lazyImage-img {
    opacity: 1;
  }
}

a.disabled:hover {
  cursor: default;
}
